//用户请求模块

import request from "@/utils/request.js"
import Qs from "qs"

//登录(总)
export const logon_api=data=>{
  return  request({
    method: 'post',
    url: 'api/v1/auth/login',
    data: Qs.stringify(data)
  });
} 
//登录(门店)
export const logon_api1=data=>{
  return  request({
    method: 'post',
    url: 'api/v1/store/auth/login',
    data: Qs.stringify(data)
  });
} 

//获取账号信息
export const getUserInfo_api=params=>{
return  request({
  method: 'get',
  url: 'api/v1/info',
  params,
});
} 

//修改密码
export const changePwd_api=data=>{
return  request({
  method: 'post',
  url: 'api/v1/updatePassword',
  data: Qs.stringify(data)
});
} 


