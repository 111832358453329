<template>
  <div class="login">
    <div class="login-box">
      <div class="login-box-left">
        <img src="@/assets/imgs/login.png" width="90%" />
      </div>
      <div class="login-box-right">
        <div class="platform">
          <img src="@/assets/imgs/logo.png" alt="" />
          <span>连锁门店管理系统</span>
        </div>
        <div class="change-shoptype">
          <div style="height: 50px; cursor: pointer" @click="active = 1">
            <div  class="shopType" :style="{ color: active == 1 ? '#CD0550' : '#999999' }">
              连锁总部登录
            </div>
            <div :class="active == 1 ? 'activeCss' : ''"></div>
          </div>
          <div style="height: 50px; cursor: pointer" @click="active = 2">
            <div class="shopType" :style="{ color: active != 1 ? '#CD0550' : '#999999' }">
              连锁门店登录
            </div>
            <div :class="active == 2 ? 'activeCss' : ''"></div>
          </div>
        </div>
        <div>
          <el-form ref="form" :model="loginFrom" label-width="80px">
            <el-form-item label="账号" style="position: relative">
              <el-input
                v-model="loginFrom.acc"
                class="login-input"
                placeholder="请输入你的门店账号"
              ></el-input>
              <div class="tips" v-show="tipsShow">
                <span>账号或者密码错误</span>
                <img src="@/assets/imgs/tips.png" />
              </div>
            </el-form-item>
            <el-form-item label="密码">
              <el-input
                show-password
                v-model="loginFrom.pwd"
                class="login-input"
                placeholder="请输入你的账号密码"
              ></el-input>
            </el-form-item>
            <el-form-item class="subimit-btn">
              <el-button type="primary" @click="submitForm(loginFrom)">登 录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logon_api, logon_api1 } from "@/api/user"
import local from "@/utils/local.js"
export default {
  data() {
    return {
      //用户名,密码
      loginFrom: {
        acc: "",
        pwd: "",
      },
      //密码错误显示
      tipsShow: false,
      active: 1
    }
  },
  methods: {
    async submitForm() {
      if (this.active == 1) {
        let data = await logon_api({
          account: this.loginFrom.acc,
          password: this.loginFrom.pwd
        })
        if (data.data.code == 0) {
          local.set("tk", data.data.data.access_token)
          local.set("user", this.active)
          local.set("is_supermarket", data.data.data.is_supermarket)
           this.$router.push("/home")
        } else {
          this.tipsShow = true
        }
      } else {
        let res = await logon_api1({
          account: this.loginFrom.acc,
          password: this.loginFrom.pwd
        })
        if (res.data.code == 0) {
          local.set("tk", res.data.data.access_token)
          local.set("user", this.active)
          local.set("is_supermarket",  res.data.data.is_supermarket)
          this.$router.push("/home")
        } else {
          this.tipsShow = true
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .shopType {
    font-size: 18px;
    font-weight: bold;
  }
  .change-shoptype {
    width: 300px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .login-box {
    width: 80vw;
    height: 80vh;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(0, 2, 1, 0.1);
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    .login-box-left {
      height: 100%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #cd0550;
    }
    .login-box-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .subimit-btn {
        display: flex;
        justify-content: center;
        margin-top: 60px;
      }
      .platform {
        display: flex;
        align-items: center;
        margin-bottom: 55px;
        img {
          width: 88px;
          height: 88px;
          margin-right: 15px;
        }
        span {
          font-size: 40px;
          font-weight: bold;
          color: #cd0550;
        }
      }
      .login-input {
        width: 400px;
        color: #333333 !important;
      }
      .tips {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        font-size: 14px;
        font-weight: bold;
        color: #cd0550;
        img {
          width: 15px;
          height: 15px;
          margin-left: 6px;
        }
      }
    }
  }
  /deep/.el-form-item__label {
    font-size: 18px;
    font-weight: bold;
    color: #999;
  }

  /deep/.el-input__inner {
    font-size: 16px;
    font-weight: bold;
    // color: #dddddd;
  }
  .el-button--primary {
    background: #cd0550;
    border: 0;
    width: 160px;
    height: 50px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }
}
.activeCss {
  width: 30px;
  height: 10px;
  background: #cd0550;
  margin: 10px auto;
  border-radius: 5px;
}
</style>