<template>
  <div class="main">
    <div class="index-header">
      <div class="index-header-left">
        <img src="@/assets/imgs/logo.png" />
        <div class="page-name">连锁门店管理系统</div>
        <i
          @click="isCollapse = !isCollapse"
          class="iconfont icon-jiluliebiao"
        ></i>
      </div>
      <div class="index-header-right">
        <div class="sign-out" @click="signOut">
          <i class="iconfont icon-guanbi"></i><span>退出</span>
        </div>
        <div class="set" @click="goSet">
          <i class="iconfont icon-shezhi"></i><span>设置</span>
        </div>
        <img src="@/assets/imgs/avatar.png" />
      </div>
    </div>
    <div class="main-content">
      <LeftMenu :isCollapse="isCollapse" />
      <div class="main-content-right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "@/components/LeftMenu.vue";
import local from "@/utils/local.js";
import { orderPush_api } from "@/api/store/order";
import { getLodop } from "@/utils/LodopFuncs.js";
//定义一个变量
var lodop;
//程序加载有个加载过程，进页面立即初始化的话，会提示未加载完成
function Init() {
  //初始化变量 str1 str2默认不填 ，是注册正版时的验证账号密码
  lodop = getLodop();
}
//执行lodop_print()即可直接打印
function lodop_print(html) {
  //需要打印的内容
  // var html = document.getElementById("testbox").innerHTML;
  lodop.PRINT_INIT();
  //设置打印页面大小，这里3表示纵向打印且纸高“按内容的高度”；48表示纸宽48mm；20表示页底空白2.0mm
  lodop.SET_PRINT_PAGESIZE(3, 580, 100, "11");
  //设置打印页面内容  10为上边距 0为左边距 100%为宽度 ""为高度，发现不填也没事，html是打印内容
  lodop.ADD_PRINT_HTM(0, 0, "100%", "100", html);
  //打印操作
  lodop.PRINT();
}

export default {
  components: {
    LeftMenu,
  },
  data() {
    return {
      info: {},
      list: [],
      isCollapse: false,
      orderData: {},
      showHtml: false,
      timer: null,
    }
  },
  methods: {
    //退出登录
    signOut() {
      local.clear();
      this.$router.push("/login");
    },
    //设置页面
    goSet() {
      window.location.hash = "/changePwd";
    },
    polling() {
      this.timer = setInterval(async () => {
        let res = await orderPush_api();
        if (res.data.code == 0) {
          if (res.data.data) {
            res.data.data.forEach((item) => {
              let html = document.createElement("div");
              html.id = "testbox";
              html.innerHTML = `<div style="font-size:32px;font-width:bolder;text-align: center;">#${
                item.pickup_code
              }</div>
        <div style="font-size:18px;font-width:bolder;text-align: center;">${
          item.shop.name
        }</div>
        <div style="display:flex;justify-content: space-between;font-size:12px; line-height:20px "><div style="width:60px">商品名称:</div><div style="font-size:11px">${
          item.order_goods.good_name
        }</div></div>
        <div style="display:flex;justify-content: space-between;font-size:12px; line-height:20px "><div style="width:60px">商品数量:</div><div style="font-size:11px">${
          item.order_goods.good_quantity
        }</div></div>
        <div style="display:flex;justify-content: space-between;font-size:12px; line-height:20px "><div style="width:60px">订单号:</div><div style="font-size:11px">${
          item.order_no
        }</div></div>
        <div style="display:flex;justify-content: space-between;font-size:12px; line-height:20px "><div style="width:60px">下单时间:</div><div style="font-size:11px">${
          item.created_at
        }</div></div>
        <div style="display:flex;justify-content: space-between;font-size:12px; line-height:20px "><div style="width:60px">取货时间:</div><div style="font-size:11px">${
          item.pickup_date
        } ${item.pickup_time_start}~${item.pickup_time_end}</div></div>
         <div style="display:flex;justify-content: space-between;font-size:12px; line-height:20px "><div style="width:60px">门市价格:</div><div style="font-size:11px">¥${
           item.order_goods.price_type == 1
             ? item.order_goods.original_price
             : item.order_goods.original_price_range_start +
               "-" +
               item.order_goods.original_price_range_end
         }</div></div>
        <div style="display:flex;justify-content: space-between;font-size:12px; line-height:20px "><div style="width:60px">订单金额:</div><div style="font-size:11px">¥${
          item.order_amount
        }</div></div>
        <p style="color:#eee">---------------------------------</p>`;
              let htmlData = html.innerHTML;
              lodop_print(htmlData);
            });
          }
        }
        console.log(1);
        clearInterval(this.timer);
        setTimeout(() => {
          this.polling();
        }, 3000);
      }, 30000);
    },
  },
  created() {
    let user = local.get("user");
    if (user == 2) {
      setTimeout(Init, 500);
      this.polling();
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.index-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
  .index-header-left,
  .index-header-right {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
    }
    i {
      color: #cd0550;
      margin-right: 5px;
    }
  }
  .page-name {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    margin: 0 15px 0 5px;
    cursor: pointer;
  }
  .sign-out,
  .set {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    margin: 0 30px 0 5px;
    cursor: pointer;
  }
}
.main-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
}
.main-content-right {
  flex: 1;
  width: 88vw;
}
</style>